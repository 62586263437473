/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import header from './header';
import auth from './auth';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
    const rootReducer = combineReducers({
        header: header,
        auth: auth,
        ...injectedReducers,
    });

    return rootReducer;
}
