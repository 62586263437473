import React, { lazy, Suspense } from 'react';
import LoadingIndicator from 'app/components/LoadingIndicator';

interface Opts {
    fallback: React.ReactNode;
}
type Unpromisify<T> = T extends Promise<infer P> ? P : never;

export const lazyLoad = <T extends Promise<any>, U extends React.ComponentType<any>>(
    importFunc: () => T,
    selectorFunc?: (s: Unpromisify<T>) => U,
    opts: Opts = { fallback: <LoadingIndicator /> },
) => {
    let lazyFactory: () => Promise<{ default: U }> = importFunc;

    if (selectorFunc) {
        lazyFactory = () => importFunc().then(module => ({ default: selectorFunc(module) }));
    }

    const LazyComponent = lazy(lazyFactory);

    return (props: React.ComponentProps<U>): JSX.Element => (
        <Suspense fallback={opts.fallback!}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

interface Props {
    fallback?: React.ReactNode | null;
}

const loadable = <T extends React.ComponentType<any>>(importFunc: () => Promise<{ default: T }>, { fallback = null }: Props = { fallback: null }) => {
    const LazyComponent = lazy(importFunc);

    return (props: React.ComponentProps<T>): JSX.Element => (
        <Suspense fallback={fallback}>
            <LazyComponent {...props} />
        </Suspense>
    );
};

export default loadable;
