import React, { Component } from 'react';
import styled from 'styled-components';

export const IconButtonStyled = styled.button`
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    min-height: 35px;
    word-break: normal;

    &:hover {
        opacity: 0.7;
    }
`;

export const IconButtonLabelStyled = styled.div`
    &.hasIcon {
        margin-left: 5px;
    }
`;

export interface IIconButton {
    label?: string;
    bgColor?: string;
    color?: string;
    icon?: any;
    opacity?: string;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    marginRight?: string;
    marginLeft?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export default class IconButton extends Component<IIconButton> {
    render() {
        const { className, color, disabled, type, onClick, bgColor, opacity, marginLeft, marginRight, icon, label } = this.props;
        return (
            <IconButtonStyled
                className={className}
                type={type ? type : 'button'}
                onClick={() => !disabled && onClick && onClick()}
                style={{
                    backgroundColor: bgColor,
                    color,
                    opacity,
                    cursor: 'pointer',
                    marginRight: marginRight,
                    marginLeft: marginLeft,
                    ...(disabled
                        ? {
                              opacity: '0.7',
                              cursor: 'not-allowed',
                          }
                        : {}),
                }}
            >
                {icon && <>{icon}</>}
                {label && <IconButtonLabelStyled className={`${icon ? 'hasIcon' : ''}`}>{label}</IconButtonLabelStyled>}
            </IconButtonStyled>
        );
    }
}
