/**
 * Asynchronously loads the component for HomePage
 */

import React from 'react';
import loadable, { lazyLoad } from 'utils/loadable';
import LoadingIndicator from 'app/components/LoadingIndicator';
import styled from 'styled-components/macro';

export default loadable(() => import('./index'), {
    fallback: <LoadingIndicator />,
});

const LoadingWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NotFoundPage = lazyLoad(
    () => import('./index'),
    // @ts-ignore
    module => module.NotFoundPage,
    {
        fallback: (
            <LoadingWrapper>
                <LoadingIndicator />
            </LoadingWrapper>
        ),
    },
);
