import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  *{
    font-family: 'Roboto', sans-serif;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
  }
  a{
    color: #0c1635;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  .rdt_TableHeader {
    padding-left: 0px!important;
    padding-right: 0px!important;
    overflow: visible!important;
    > div:first-of-type{
      text-align: left;
    }
    > div:last-of-type{
      display:none;
    }
  }
  .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{
    font-weight: 800;
  }

  .swal-button-container{
    .swal-button.swal-button--confirm {
      background-color: #249c24;
      &:active {
        background-color: #249c24;
      }
      &:focus {
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgb(36 156 36 / 29%);
      }
      &:not([disabled]):hover {
        background-color: #249c24;
      }
    }
  }
  .swal2-actions{
      .swal2-confirm {
      background-color: #249c24!important;
      &:active {
        background-color: #249c24!important;
      }
      &:focus {
        box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgb(36 156 36 / 29%);
      }
      &:not([disabled]):hover {
        background-color: #249c24!important;
      }
    }
  }

  .swal2-html-container{
    z-index: 10;
    overflow: visible;
  }

  .userlist{
    .rdt_TableBody{
      padding-bottom: 5px;
    }
  }

  
  .rdt_TableCol {
    font-size: 12px;
    word-break: normal;
    text-align: center;
    padding-left: 2px!important;
    padding-right: 2px!important;
  }

  .react-tooltip{
    position: absolute;
    right: 0;
    }

  .theadresearch {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #dbdbdb;
  }

  .thresearch {
    padding-right: 110px;
    font-size: 95%;
  }
  .tbresearch {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #dbdbdb;
  }

  .button-disable{
    cursor: not-allowed;
  }
  .swal2-close{
    margin-bottom: -30px;
  }

  .buttonSideBar{
    background-color: #F5F5F5;
    position : absolute;
    border: none;
    right: 0;
    top: 100%;
    width: 30px;
    height: 40px;
    border-radius: 150px 0 0 150px;
  &:hover{
    cursor: pointer;
  }
}

  .viewerclose{
    color: black;
    float: right;
    margin-right:10px;
    margin-top:10px;
    z-index: 999;
    cursor: pointer;
    position: absolute;
    top:0px;
    right:-50px;
    svg{
      padding:5px;
      width:30px;
      height:30px;
      color:#fff !important;
      background-color:#252525;
      border-radius:500px;
    }
  }

  .viewerrounded{
    iframe{
      border-radius:10px;
    }
  }

  .inline{
    display: inline-block;
    width: 100%;
  }
`;
