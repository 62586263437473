/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import NotFoundPage from './pages/NotFoundPage/Loadable';

import ArchiveListPage from './pages/ArchivePage/List/Loadable';

import SearchPage from './pages/SearchPage/Search/Loadable';

import StatistiquePage from './pages/StatistiquePage/Statistique/Loadable';

import AuthLoginPage from './pages/AuthPage/Login/Loadable';
import AuthLogoutPage from './pages/AuthPage/Logout/Loadable';
import AuthLogoutSuccessPage from './pages/AuthPage/LogoutSuccess/Loadable';

import SiteListPage from './pages/SitePage/List/Loadable';
import SiteCreatePage from './pages/SitePage/Create/Loadable';
import SiteUpdatePage from './pages/SitePage/Update/Loadable';
import SiteDetailsPage from './pages/SitePage/Details/Loadable';

import ReviewListPage from './pages/ReviewPage/List/Loadable';
import ReviewCreatePage from './pages/ReviewPage/Create/Loadable';
import ReviewUpdatePage from './pages/ReviewPage/Update/Loadable';
import ReviewDetailsPage from './pages/ReviewPage/Details/Loadable';

import EventDetailsPage from './pages/EventPage/Details/Loadable';
import EventListPage from './pages/EventPage/List/Loadable';
import EventCreatePage from './pages/EventPage/Create/Loadable';
import EventUpdatePage from './pages/EventPage/Update/Loadable';
import EventFollowedPage from './pages/EventPage/Followed/Loadable';
import EventArchivedPage from './pages/EventPage/Archived/Loadable';

import ProgrammationDetailsPage from './pages/ProgrammationPage/Details/Loadable';
import ProgrammationCreatePage from './pages/ProgrammationPage/Create/Loadable';
import ProgrammationUpdatePage from './pages/ProgrammationPage/Update/Loadable';

import SchemaDetailsPage from './pages/SchemaPage/Details/Loadable';
import SchemaCreatePage from './pages/SchemaPage/Create/Loadable';
import SchemaUpdatePage from './pages/SchemaPage/Update/Loadable';

import AbonnementListPage from './pages/AbonnementPage/List/Loadable';
import AbonnementDetailsPage from './pages/AbonnementPage/Details/Loadable';
import AbonnementCreatePage from './pages/AbonnementPage/Create/Loadable';
import AbonnementUpdatePage from './pages/AbonnementPage/Update/Loadable';

import DocumentListPage from './pages/DocumentPage/List/Loadable';
import DocumentCreatePage from './pages/DocumentPage/Create/Loadable';
import DocumentDetailsPage from './pages/DocumentPage/Details/Loadable';
import DocumentUpdatePage from './pages/DocumentPage/Update/Loadable';

import TechnologyListPage from './pages/TechnologyPage/List/Loadable';
import TechnologyCreatePage from './pages/TechnologyPage/Create/Loadable';
import TechnologyUpdatePage from './pages/TechnologyPage/Update/Loadable';

import LitigeListPage from './pages/LitigePage/List/Loadable';
import LitigeTypeCreatePage from './pages/LitigePage/TypeCreate/Loadable';
import LitigeTypeUpdatePage from './pages/LitigePage/TypeUpdate/Loadable';
import LitigeStatusCreatePage from './pages/LitigePage/StatusCreate/Loadable';
import LitigeStatusUpdatePage from './pages/LitigePage/StatusUpdate/Loadable';

import ServiceListPage from './pages/ServicePage/List/Loadable';

import EmailListPage from './pages/EmailPage/List/Loadable';
import EmailCreatePage from './pages/EmailPage/Create/Loadable';
import EmailUpdatePage from './pages/EmailPage/Update/Loadable';

import UserListPage from './pages/UserPage/List/Loadable';

import Header from './components/Header';
import Sidebar from './components/Sidebar';

import { GlobalStyle } from 'styles/global-styles';
import { connect } from 'react-redux';
import { checkLogin } from 'store/auth';
import { userHasPermission } from 'utils/permissions';

import eventBus from './EventBus';

const AppLayout = styled.div`
    margin: 0 auto;
    display: flex;
`;

const AppWrapper = styled.div`
    padding: 90px 20px 20px 20px;
    background: #f5f5f5;
`;
const LogoutAppWrapper = styled.div`
    padding: 20px 20px 20px 20px;
    background: #f5f5f5;
`;
const AppChildWrapper = styled.div`
    background: #ffffff;
    border-radius: 5px;
    padding: 30px;
`;

class App extends Component {
    state = {
        sidebarOpen: true,
    };

    componentDidMount() {
        this.props['checkLogin']();
    }

    componentDidUpdate() {
        eventBus.on('sidebarState', data => this.setState({ sidebarOpen: data.sidebarOpen }));
    }

    render() {
        const AppContent = styled.div`
            width: 100%;
            flex: 1;
            padding-left: ${this.state.sidebarOpen ? '200px' : '70px'};
            @media (max-width: 1024px) {
                padding-left: 70px;
            }
        `;

        if (!this.props['auth']['user']) {
            return (
                <AppLayout>
                    <BrowserRouter>
                        <Sidebar logout />
                        <AppContent>
                            <LogoutAppWrapper>
                                <AppChildWrapper>
                                    <Switch>
                                        <Route path="/logout" component={AuthLogoutSuccessPage} />
                                        <Route path="" component={AuthLoginPage} />
                                    </Switch>
                                </AppChildWrapper>
                            </LogoutAppWrapper>
                        </AppContent>
                        <GlobalStyle />
                    </BrowserRouter>
                </AppLayout>
            );
        } else {
            return (
                <AppLayout>
                    <BrowserRouter>
                        <Sidebar />
                        <AppContent>
                            <Header />
                            <AppWrapper>
                                <AppChildWrapper>
                                    <Switch>
                                        {/* AUTH */}
                                        <Route path="/logout" component={AuthLogoutPage} />

                                        {/* ARCHIVES */}
                                        <Route exact path="/archives" component={ArchiveListPage} />

                                        <Route exact path="/search" component={SearchPage} />

                                        {/* SITES */}
                                        <Route exact path="/" component={SiteListPage} />
                                        <Route exact path="/site/create" component={SiteCreatePage} />
                                        <Route exact path="/site/:idSite/update" component={SiteUpdatePage} />
                                        <Route exact path="/site/:idSite/detail" component={SiteDetailsPage} />

                                        {/* RAPPORTS */}
                                        <Route exact path="/site/:idSite/reviews/create" component={ReviewCreatePage} />
                                        <Route exact path="/site/:idSite/reviews/:idSubmoduleModel" component={ReviewDetailsPage} />
                                        <Route exact path="/site/:idSite/reviews/:idSubmoduleModel/update" component={ReviewUpdatePage} />
                                        <Route exact path="/site/:idSite/reviews" component={ReviewListPage} />

                                        {/* ÉVÉNEMENTS */}
                                        <Route exact path="/events/followed" component={EventFollowedPage} />
                                        <Route exact path="/events/archived" component={EventArchivedPage} />
                                        <Route exact path="/site/:idSite/events/create" component={EventCreatePage} />
                                        <Route exact path="/site/:idSite/events" component={EventListPage} />
                                        <Route exact path="/site/:idSite/events/:idSubmoduleModel" component={EventDetailsPage} />
                                        <Route exact path="/site/:idSite/events/:idSubmoduleModel/update" component={EventUpdatePage} />

                                        {/* SERVICES */}
                                        {<Route exact path="/site/:idSite/services" component={ServiceListPage} />}

                                        {/* PROGRAMMATIONS */}
                                        <Route exact path="/site/:idSite/programmations/create" component={ProgrammationCreatePage} />
                                        {/*<Route exact path="/site/:idSite/programmations" component={ProgrammationListPage} />*/}
                                        <Route exact path="/site/:idSite/programmations/:idSubmoduleModel" component={ProgrammationDetailsPage} />
                                        <Route
                                            exact
                                            path="/site/:idSite/programmations/:idSubmoduleModel/update"
                                            component={ProgrammationUpdatePage}
                                        />

                                        {/* DOCUMENTS */}
                                        <Route exact path="/site/:idSite/documents" component={DocumentListPage} />
                                        <Route exact path="/site/:idSite/documents/create" component={DocumentCreatePage} />
                                        <Route exact path="/site/:idSite/documents/:idSubmoduleModel" component={DocumentDetailsPage} />
                                        <Route exact path="/site/:idSite/documents/:idSubmoduleModel/update" component={DocumentUpdatePage} />
                                        {/* ABONNEMENT */}
                                        <Route exact path="/site/:idSite/abonnements" component={AbonnementListPage} />
                                        <Route exact path="/site/:idSite/abonnements/create" component={AbonnementCreatePage} />
                                        <Route exact path="/site/:idSite/abonnements/:idSubmoduleModel" component={AbonnementDetailsPage} />
                                        <Route exact path="/site/:idSite/abonnements/:idSubmoduleModel/update" component={AbonnementUpdatePage} />

                                        {/* SCHEMAS */}
                                        {/*<Route exact path="/site/:idSite/schemas" component={SchemaListPage} />*/}
                                        <Route exact path="/site/:idSite/schemas/create" component={SchemaCreatePage} />
                                        <Route exact path="/site/:idSite/schemas/:idSubmoduleModel/update" component={SchemaUpdatePage} />
                                        <Route exact path="/site/:idSite/schemas/:idSubmoduleModel" component={SchemaDetailsPage} />

                                        {/* TECHNOLOGIES */}
                                        <Route exact path="/technologies" component={TechnologyListPage} />
                                        <Route exact path="/technologies/create" component={TechnologyCreatePage} />
                                        <Route exact path="/technologies/:idTechnology/update" component={TechnologyUpdatePage} />

                                        {/* LITIGES */}
                                        <Route exact path="/litiges" component={LitigeListPage} />
                                        <Route exact path="/litiges/type/create" component={LitigeTypeCreatePage} />
                                        <Route exact path="/litiges/type/:idLitige/update" component={LitigeTypeUpdatePage} />

                                        <Route exact path="/litiges/status/create" component={LitigeStatusCreatePage} />
                                        <Route exact path="/litiges/status/:idLitige/update" component={LitigeStatusUpdatePage} />

                                        {/* USERS */}
                                        <Route exact path="/users" component={UserListPage} />

                                        {/* EMAILS */}
                                        <Route exact path="/emails" component={EmailListPage} />
                                        <Route exact path="/emails/create" component={EmailCreatePage} />
                                        <Route exact path="/emails/:idEmail/update" component={EmailUpdatePage} />
                                        {userHasPermission('stats', undefined) && <Route exact path="/statistiques" component={StatistiquePage} />}
                                        {/* NOT FOUND */}
                                        <Route path="" component={NotFoundPage} />
                                    </Switch>
                                </AppChildWrapper>
                            </AppWrapper>
                        </AppContent>
                        <GlobalStyle />
                    </BrowserRouter>
                </AppLayout>
            );
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default hot(connect(mapStateToProps, { checkLogin })(App));
