import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconButtonStyled } from 'app/components/IconButton';

export const AStyled = styled(Link)`
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    text-decoration: none !important;
    &:hover {
        opacity: 0.7;
    }
    ${IconButtonStyled}:hover {
        opacity: 1;
    }
`;
export interface IA {
    color?: string;
    to: string;
    className?: string;
    children?: any;
    permission?: string;
}

export default class A extends Component<IA> {
    render() {
        return (
            <AStyled className={this.props.className} to={this.props.to} style={{ color: this.props.color }}>
                {this.props.children}
            </AStyled>
        );
    }
}
