import React, { Component } from 'react';
import styled from 'styled-components';
import IconButton from 'app/components/IconButton';
import A, { AStyled } from 'app/components/A';
import { IoIosAddCircleOutline, IoIosFlame, IoIosMail, IoIosStats } from 'react-icons/io';
import { BsCardList } from 'react-icons/bs';
import { FaFileArchive } from 'react-icons/fa';
import { IoIosStar } from 'react-icons/io';
import { IoIosArchive } from 'react-icons/io';

import { CgUserList } from 'react-icons/cg';
import { AiOutlineCloudServer } from 'react-icons/ai';

import Logo from './assets/logo.svg';
import Img from 'app/components/Img';
import { connect } from 'react-redux';
import Permission from '../Permission';

import { GiHamburgerMenu } from 'react-icons/gi';
import eventBus from '../../EventBus';
import { userHasPermission, userIsContributor } from 'utils/permissions';

const SidebarFooterStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 25px 15px 50px 12px;
    ${AStyled} {
        margin-top: 15px;
    }
    @media (max-width: 1024px) {
        padding: 25px 0px 25px 0px;
        align-items: center;
    }
`;

const SidebarHeaderStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${AStyled} {
        margin-top: 15px;
    }
    > .link-logo {
        margin-bottom: 25px;
        > img {
            width: 170px;
        }
    }
    @media (max-width: 1024px) {
        > .link-logo {
            margin-top: 8px;
            > img {
                width: 55px;
            }
        }
    }
`;

const LoggedUsername = styled.span`
    font-weight: 600;
`;

interface ISidebar {
    logout?: boolean;
}

class Sidebar extends Component<ISidebar> {
    state = {
        sidebarOpen: true,
    };

    render() {
        const SidebarStyled = styled.div`
            width: ${this.state.sidebarOpen ? '200px' : '70px'};
            position: fixed;
            left: 0px;
            top: 0px;
            height: 100vh;
            background-color: #0c1635;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            transition: all 0.3s ease-in-out;

            .logged-in {
                display: ${this.state.sidebarOpen ? 'block !important' : 'none !important'};
            }
            .showFlexInMobile {
                display: ${this.state.sidebarOpen ? 'none !important' : 'flex !important'};
            }
            .link-logo img {
                width: ${this.state.sidebarOpen ? '' : '50px'};
            }
            .hideInMobile {
                display: ${this.state.sidebarOpen ? 'flex !important' : 'none !important'};
            }
            p {
                color: white;
                font-size: 0.85rem;
                text-align: center;
            }

            @media (max-width: 1024px) {
                width: 70px;
                .hideInMobile {
                    display: none !important;
                }
                .logged-in {
                    display: none !important;
                }
                .showFlexInMobile {
                    display: flex !important;
                }
                > .link-logo {
                    margin-top: 8px;
                    > img {
                        width: 50px;
                    }
                }
            }
        `;

        return (
            <SidebarStyled>
                <SidebarHeaderStyled style={{ position: 'relative' }}>
                    <button
                        onClick={() => {
                            this.setState({
                                sidebarOpen: !this.state.sidebarOpen,
                            });
                            eventBus.dispatch('sidebarState', { sidebarOpen: !this.state.sidebarOpen });
                        }}
                        className="buttonSideBar"
                    >
                        <GiHamburgerMenu />
                    </button>
                    <A className="link-logo" to="/">
                        <Img src={Logo} alt="Urmet group - Logo" />
                    </A>
                    {!this.props.logout && (
                        <>
                            <p className="logged-in">
                                Connecté en tant que: <br />
                                <LoggedUsername>{this.props['auth'].user.name}</LoggedUsername>
                            </p>

                            <A className="hideInMobile" to="/" color="#ffffff">
                                Sites techniques
                            </A>
                            <A className="showFlexInMobile" to="/" color="#ffffff">
                                <IconButton icon={BsCardList({ size: 20 })} bgColor="transparent" color="#ffffff" />
                            </A>

                            {(userHasPermission('admin', 'W') || userIsContributor()) && (
                                <>
                                    <A className="hideInMobile" to="/events/followed" color="#ffffff">
                                        Évènements suivis
                                    </A>
                                    <A className="showFlexInMobile" to="/events/followed" color="#ffffff">
                                        <IconButton icon={IoIosStar({ size: 20 })} bgColor="transparent" color="#ffffff" />
                                    </A>
                                </>
                            )}
                        </>
                    )}
                </SidebarHeaderStyled>

                {!this.props.logout && (
                    <>
                        <SidebarFooterStyled>
                            <Permission permission="users">
                                <A to="/users">
                                    <IconButton
                                        className="hideInMobile"
                                        label="Utilisateurs"
                                        icon={IoIosAddCircleOutline({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                    <IconButton
                                        className="showFlexInMobile"
                                        icon={CgUserList({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                </A>
                            </Permission>
                            <Permission permission="admin">
                                <A to="/technologies">
                                    <IconButton
                                        className="hideInMobile"
                                        label="Technologies"
                                        icon={IoIosAddCircleOutline({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                    <IconButton
                                        className="showFlexInMobile"
                                        icon={AiOutlineCloudServer({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                </A>
                                <A to="/litiges">
                                    <IconButton
                                        className="hideInMobile"
                                        label="Litiges"
                                        icon={IoIosAddCircleOutline({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                    <IconButton
                                        className="showFlexInMobile"
                                        icon={IoIosFlame({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                </A>
                            </Permission>
                            <A to="/emails">
                                <IconButton
                                    className="hideInMobile"
                                    label="Emails"
                                    icon={IoIosAddCircleOutline({ size: 20, color: '#ffffff' })}
                                    bgColor="transparent"
                                    color="#ffffff"
                                />
                                <IconButton
                                    className="showFlexInMobile"
                                    icon={IoIosMail({ size: 20, color: '#ffffff' })}
                                    bgColor="transparent"
                                    color="#ffffff"
                                />
                            </A>
                            <Permission permission="stats">
                                <A to="/statistiques">
                                    <IconButton
                                        className="hideInMobile"
                                        label="Statistiques"
                                        icon={IoIosStats({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                    <IconButton
                                        className="showFlexInMobile"
                                        icon={IoIosStats({ size: 20, color: '#ffffff' })}
                                        bgColor="transparent"
                                        color="#ffffff"
                                    />
                                </A>
                            </Permission>
                        </SidebarFooterStyled>
                    </>
                )}
            </SidebarStyled>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Sidebar);
