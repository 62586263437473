import { AStyled } from 'app/components/A';
import axiosService from 'app/context/service';
import React, { Component } from 'react';
import { IoIosSearch } from 'react-icons/io';
import styled from 'styled-components';

const SearchStyled = styled.div`
    margin-right: 15px;
    .IoIosSearchHeader {
        cursor: pointer;
    }
`;

const InputContainer = styled.div`
    position: relative;
    > input {
        &:disabled {
            cursor: pointer !important;
        }
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: 6px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-style: normal;
        font-weight: 400;
        width: 300px;
        padding: 0.5rem 0.75rem;
        box-shadow: rgb(0 0 0 / 10%) 2px 2px 6px;
        padding-right: 35px;

        &:focus,
        &:active {
            outline: none;
        }
    }

    > svg {
        position: absolute;
        right: 10px;
        top: 10px;
    }
`;

const SearchModal = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

const SearchModalBackdrop = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
`;
const SearchModalContent = styled.div`
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    max-width: 600px;
    width: 600px;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: rgb(0 0 0 / 10%) 2px 2px 6px;
    overflow: hidden;
`;

const SearchModalHeader = styled.div`
    width: 100%;
    ${InputContainer} {
        width: 100%;
        > input {
            width: 100%;
            padding: 15px 45px 15px 15px;
            font-size: 18px;
            border-radius: 0px;
            border-left: none;
            border-right: none;
        }
        > svg {
            top: 14px;
            right: 14px;
        }
    }
`;
const SearchModalBody = styled.div`
    padding: 15px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const SearchNoContent = styled.div`
    padding: 10px 0px;
`;

const SearchResult = styled.div``;
const SearchItems = styled.div``;
const SearchGroup = styled.div`
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0px;
    }
`;

const SearchGroupTitle = styled.div`
    color: #8f8f8f;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #c5c5c5;
    padding-bottom: 5px;
    margin-bottom: 10px;
`;

const SearchItem = styled.div`
    width: 100%;
    &:hover {
        background-color: #f0f0f0;
    }
    ${AStyled} {
        padding: 5px 5px;
        width: 100%;
        display: block;
    }
`;
const TagItem = styled.span`
    text-align: center;
    padding: 4px;
    background: #0c1635;
    margin: 2px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 10px;
    display: inline-flex;
    color: rgb(255 255 255 / 60%);
    b {
        color: #ffffff;
    }
`;

const TagItems = styled.div`
    ${TagItem} {
        &:first-of-type {
            margin-left: 0px;
        }
        &:last-of-type {
            margin-right: 0px;
        }
    }
`;

const CancelToken = axiosService.CancelToken;
let cancel;

class Search extends Component {
    state = {
        showModal: false,
        nbResults: 0,
        results: {},
        archives: {},
        resultsfinal: {},
        nbResultsfinal: 0,
        totalArchives: 0,
        resultsCopy: {},
    };

    search = async value => {
        if (value === '') {
            return;
        }
        if (cancel) {
            cancel();
        }
        try {
            const response = await axiosService.get(`search?search=${value}`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
            });
            if (response.status === 200) {
                this.setState({ nbResults: response.data.nbResults, results: response.data.results });
            }
        } catch (error) {}
    };

    closeModal = () => {
        this.setState({ showModal: false, nbResults: 0, results: {}, archives: {}, totalArchives: 0 });
    };

    render() {
        return (
            <SearchStyled>
                <IoIosSearch
                    className="IoIosSearchHeader"
                    onClick={() => {
                        this.setState({ showModal: true });
                    }}
                    size="30px"
                    color="#0c1635"
                />
                {this.state.showModal && (
                    <SearchModal>
                        <SearchModalBackdrop onClick={() => this.closeModal()} />
                        <SearchModalContent>
                            <SearchModalHeader>
                                <InputContainer>
                                    <input autoFocus onChange={e => this.search(e.target.value)} placeholder="Recherche rapide" />
                                    <IoIosSearch size="28px" color="#ababab" />
                                </InputContainer>
                            </SearchModalHeader>
                            <SearchModalBody>
                                {this.state.nbResults <= 0 ? (
                                    <>
                                        <SearchNoContent>Aucun résultat ne correspond à votre recherche.</SearchNoContent>
                                    </>
                                ) : (
                                    <>
                                        <SearchResult>
                                            {this.state.results['sites-technos'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>
                                                            Sites techniques pour cette technologie ({this.state.results['sites-technos'].length})
                                                        </SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['sites-technos'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`sites_technos${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event => (window.location.href = `/site/${e.id}/detail`)}
                                                                            dangerouslySetInnerHTML={{ __html: e.name }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['sites'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Sites techniques ({this.state.results['sites'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['sites'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`sites_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event => (window.location.href = `/site/${e.id}/detail`)}
                                                                            dangerouslySetInnerHTML={{ __html: e.name }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['reviews'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Compte-rendus ({this.state.results['reviews'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['reviews'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`reviews_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event =>
                                                                                (window.location.href = `/site/${e.siteId}/reviews/${e.id}`)
                                                                            }
                                                                            dangerouslySetInnerHTML={{ __html: `${e.name} ( ${e.siteName} )` }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['events'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Évènements ({this.state.results['events'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['events'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`events_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event =>
                                                                                (window.location.href = `/site/${e.siteId}/events/${e.id}`)
                                                                            }
                                                                            dangerouslySetInnerHTML={{ __html: `${e.name} ( ${e.siteName} )` }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['programs'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Programmations ({this.state.results['programs'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['programs'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`programs_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event =>
                                                                                (window.location.href = `/site/${e.siteId}/programmations/${e.id}`)
                                                                            }
                                                                            dangerouslySetInnerHTML={{ __html: `${e.name} ( ${e.siteName} )` }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['schemas'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Schémas ({this.state.results['schemas'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['schemas'].map((e, key) => {
                                                                return (
                                                                    <>
                                                                        <SearchItem key={`schemas_${key}`} onClick={() => this.closeModal()}>
                                                                            <div>
                                                                                <span
                                                                                    className="inline"
                                                                                    onClick={event =>
                                                                                        (window.location.href = `/site/${e.siteId}/schemas/${e.id}`)
                                                                                    }
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: `${e.name} ( ${e.siteName} )`,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {e.matches.tags && (
                                                                                <>
                                                                                    <TagItems>
                                                                                        {e.matches.tags.split('@|@').map((mt, key) => {
                                                                                            return (
                                                                                                <TagItem>
                                                                                                    <span
                                                                                                        className="inline"
                                                                                                        onClick={event =>
                                                                                                            (window.location.href = `/site/${e.siteId}/schemas/${e.id}`)
                                                                                                        }
                                                                                                        dangerouslySetInnerHTML={{ __html: mt }}
                                                                                                    />
                                                                                                </TagItem>
                                                                                            );
                                                                                        })}
                                                                                    </TagItems>
                                                                                </>
                                                                            )}
                                                                        </SearchItem>
                                                                    </>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['documents'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Documents ({this.state.results['documents'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['documents'].map((e, key) => {
                                                                return (
                                                                    <>
                                                                        <SearchItem key={`documents_${key}`} onClick={() => this.closeModal()}>
                                                                            <div>
                                                                                <span
                                                                                    className="inline"
                                                                                    onClick={event =>
                                                                                        (window.location.href = `/site/${e.siteId}/documents/${e.id}`)
                                                                                    }
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: `${e.name} ( ${e.siteName} )`,
                                                                                    }}
                                                                                ></span>
                                                                            </div>
                                                                            {e.matches.tags && (
                                                                                <>
                                                                                    <TagItems>
                                                                                        {e.matches.tags.split('@|@').map((mt, key) => {
                                                                                            return (
                                                                                                <TagItem>
                                                                                                    <span
                                                                                                        className="inline"
                                                                                                        onClick={event =>
                                                                                                            (window.location.href = `/site/${e.siteId}/documents/${e.id}`)
                                                                                                        }
                                                                                                        dangerouslySetInnerHTML={{ __html: mt }}
                                                                                                    ></span>
                                                                                                </TagItem>
                                                                                            );
                                                                                        })}
                                                                                    </TagItems>
                                                                                </>
                                                                            )}
                                                                        </SearchItem>
                                                                    </>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['technologies'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>
                                                            Technologies ({this.state.results['technologies'].length})
                                                        </SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['technologies'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`technologies_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event => (window.location.href = `/technologies/${e.id}/update`)}
                                                                            dangerouslySetInnerHTML={{ __html: `${e.name}` }}
                                                                        ></span>
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['litiges'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Litiges ({this.state.results['litiges'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['litiges'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`litiges_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event => (window.location.href = `/litiges/${e.id}/update`)}
                                                                            dangerouslySetInnerHTML={{ __html: `${e.name}` }}
                                                                        ></span>
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['archives'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Archives ({this.state.results['archives'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['archives'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`sites_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event => (window.location.href = `/site/${e.id}/detail`)}
                                                                            dangerouslySetInnerHTML={{ __html: e.name }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                            {this.state.results['abonnements'] && (
                                                <>
                                                    <SearchGroup>
                                                        <SearchGroupTitle>Abonnements ({this.state.results['abonnements'].length})</SearchGroupTitle>
                                                        <SearchItems>
                                                            {this.state.results['abonnements'].map((e, key) => {
                                                                return (
                                                                    <SearchItem key={`sites_${key}`} onClick={() => this.closeModal()}>
                                                                        <span
                                                                            className="inline"
                                                                            onClick={event =>
                                                                                (window.location.href = `/site/${e.siteId}/abonnements/${e.id}`)
                                                                            }
                                                                            dangerouslySetInnerHTML={{ __html: e.name }}
                                                                        />
                                                                    </SearchItem>
                                                                );
                                                            })}
                                                        </SearchItems>
                                                    </SearchGroup>
                                                </>
                                            )}
                                        </SearchResult>
                                    </>
                                )}
                            </SearchModalBody>
                        </SearchModalContent>
                    </SearchModal>
                )}
            </SearchStyled>
        );
    }
}

export default Search;
