import React, { Component } from 'react';

interface IImg {
    src: string | object;
    alt?: string;
    className?: string;
}

export default class Img extends Component<IImg> {
    render() {
        return <img className={this.props.className} src={this.props.src as string} alt={this.props.alt} />;
    }
}
